@tailwind base;

@layer base {
  body {
    font-family: 'Gilroy', sans-serif;
    background: #fff;
    font-display: swap;
  }

  h1 {
    @apply text-4xl;
    font-weight: bold;
  }
  h2 {
    @apply text-3xl;
    font-weight: bold;
  }
  h3 {
    @apply text-2xl;
    font-weight: 600;
  }
  h4 {
    @apply text-lg;
    font-weight: bold;
  }
  h5 {
    @apply text-base;
    font-weight: 600;
  }
  p {
    @apply text-lg;
    font-weight: normal;
  }
  a {
    @apply text-primary;
  }
}

@tailwind components;

@tailwind utilities;

/* Add Custom styles here */

html,
body,
#__next {
  height: 100%;
}

.container {
  max-width: 1650px;
}

.writing-mode-vertical-lr {
  writing-mode: vertical-lr;
}

.writing-mode-vertical-rl {
  writing-mode: vertical-rl;
}
